import FinanceApi from '@/services/FinanceApi';

const getAll = async (config) => {
    return FinanceApi.get('/products', config);
}

const get = async (id) => {
    return FinanceApi.get('/products/' + id);
}

const store = async (data) => {
    if (data.id) {
        return FinanceApi.put('/products/' + data.id, data);
    }
    return FinanceApi.post('/products', data);
}

const update = async (id, formData) => {
    return FinanceApi.put('/products/' + id, formData);
}

const deleteProduct = async (code) => {
    return FinanceApi.delete('/products/' + code);
}

export default {
    getAll,
    get,
    store,
    update,
    deleteProduct
}
